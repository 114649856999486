import React from "react"
import { Container, Row, Col } from "react-bootstrap"

export default function GDPR() {
  return (
    <Container>
      <Row>
        <Col>
          <h2>Privacy Policy</h2>
          <p>
            Compulease Ltd takes seriously the privacy of those that visit our
            website. We have established this Privacy Policy to communicate what
            information we collect and how that information is used and
            safeguarded.
          </p>

          <p>
            By accessing our website, you acknowledge the terms and conditions
            expressed herein. We reserve the right to change this policy at our
            sole discretion and without notice.
          </p>
          <h3>What information is collected, and why?</h3>
          <p>
            When a user visits our website, we collect information about the
            visit that does not identify the user personally. We track
            information such as the domain from which the user is visiting and
            the user’s browser type. We also collect specific information
            regarding the user’s session on our website. This includes items
            such as the date and time of the visit and the pages viewed.
          </p>
          <p>
            Sometimes we ask a user to voluntarily provide personally
            identifiable information (PII). This information generally includes,
            but is not limited to, name, email address, postal address, and
            telephone number. We request this information when the user requests
            one of the following:
          </p>
          <ol>
            <li>To register to attend an online demonstration</li>
            <li>To receive specific information from us</li>
            <li>To sign up for a mailing list</li>
            <li>To correspond with us</li>
            <li>Or any other such activity that we deem necessary</li>
          </ol>

          <p>
            Compulease Ltd does not collect any personally identifiable
            financial or health-related information. We do not intentionally
            collect information from children under the age of 13.
          </p>
          <h3>How is the information used?</h3>
          <p>
            Non-personally identifiable information (non-PII) is collected to
            allow us to analyse how our website is used and to improve the
            content and service the website provides.
          </p>
          <p>
            Personally, identifiable information is collected for analysis to
            improve the content of the website and the services we provide the
            user.
          </p>
          <p>
            Compulease Ltd owns the collected information and will not sell,
            trade, or rent the information to others. We may use the information
            to continue contact with the user. This may include contacts such as
            follow-up calls, email correspondence, or mail correspondence to
            follow-up on the contact or to provide marketing information about
            promotions, new services, or important website changes. Compulease
            Ltd may share this information with its business partners for the
            specific purpose of a promotion or service offering.
          </p>
          <h3>Are “Cookies” used on the website?</h3>
          <p>
            “Cookies” are small pieces of information that are placed on a web
            user’s hard drive. We do use cookies to provide you with a better
            service. Cookies are pieces of information that a website transfers
            to your computer’s hard disk for record-keeping purposes. Cookies
            can make the Web more useful by storing information about your
            preferences for a particular site. The use of cookies is an industry
            standard, and many major websites use them to provide useful
            features for their customers. Cookies in and of themselves do not
            personally identify users, although they do identify a user’s
            computer. Most browsers are initially set to accept cookies. If you
            prefer, you can set your browser to refuse cookies. However, you may
            not be able to take full advantage of the functionality of the
            Compulease Ltd site if you do so.
          </p>
          <h3>How is personally identifiable information safeguarded?</h3>
          <p>
            Compulease Ltd utilises industry standard methods and mechanisms
            such as firewalls, intrusion monitoring, and passwords to protect
            electronic information. Multiple physical security methods such as
            locking devices and 24-hour premise monitoring are also employed.
            Finally, access to PII is limited to a small number of personnel on
            a need-to-know basis.
          </p>
          <h3>Links to other websites</h3>
          <p>
            Compulease Ltd website may contain links to other internet websites.
            Compulease Ltd neither controls nor endorses external sites and is
            not responsible for the content of such sites. This policy does not
            cover the privacy policies and data collection practices of
            non-Compulease Ltd websites.
          </p>
          <h3>How can I opt out of receiving correspondence?</h3>
          <p>
            Based on the information a user has provided, Compulease Ltd may
            send email correspondence to the user. Each correspondence will
            contain an easy method to opt out of further correspondence. You can
            also request that your data be deleted by contacting
            info@compulease.co.uk.
          </p>
          <h3>How can I request the deletion of my data?</h3>
          <p>
            You can request that your data be deleted by sending an email to
            info@compulease.co.uk. You will need to go through a security
            process in order for us to verify that you are the owner of that
            data. Once passed, we’ll remove most personally identifiable data
            from our records. We will retain some information on what’s called a
            ‘blacklist’.
          </p>
          <h3>What is a blacklist?</h3>
          <p>
            A blacklist is a list of users that do not want to be contacted
            under any circumstances. Users that have requested the deletion of
            their data, will be added to this list. We will keep hold of your
            email address, to make sure that our future correspondence isn’t
            sent to you by mistake. If you wish to receive future
            correspondence, all you need to do is fill out a form and agree to
            the terms & conditions again. Please note that opting back in after
            being added to the blacklist, will require you to fill out all of
            your previous data again. For example, your name and telephone
            number.
          </p>
        </Col>
      </Row>
    </Container>
  )
}
