import React from "react"
import Layout from "../components/Layout"
import GDPRComponent from "../components/GDPR"
import SEO from "../components/seo"

export default function TermsAndConditions() {
  return (
    <Layout headerType="index">
      <SEO title="Compulease · GDPR" />
      <GDPRComponent />
    </Layout>
  )
}
